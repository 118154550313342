













































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ExaSignature } from '@exatech-group/signature'
import '@exatech-group/signature/dist/style.css'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        ExaSignature,
        EditorTinyMCE,
        VuePdfApp
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('pv', ['pvSelect', 'loading', 'error', 'source_pv_pdf', 'reconduction_sans_decision', 'nouvelle_candidature_sans_decision'])
    },
    methods: {
        ...mapActions('pv', ['getPv', 'updatePv', 'getPDF'])
    }
})

export default class GestionPV extends Vue {
    modeRedaction = false
    modeConsultation = false
    modeSignature = false
    modeValidation = false
    showError = false
    showWarning = false
    message_error = ''
    contentPV: any = ''
    editor = null
    source_doc: any = ''
    checkEnCours = false
    Ability = Ability
    signatureMode = []
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    @Watch('pvSelect', { immediate: true })
    setInterface() {
        const pv = this.$store.getters['pv/pvSelect']
        if (pv) {
            this.contentPV = pv?.datas || ''
            if (!this.checkEnCours) {
                this.checkEtatInterface()
            }
        }
    }

    @Watch('error')
    setError() {
        this.showError = this.$store.getters['pv/error'] !== null;
    }

    @Watch('source_pv_pdf')
    loadSourceDoc() {
        this.source_doc = ''
        const source_pv_pdf = this.$store.getters['pv/source_pv_pdf']
        if (source_pv_pdf !== '') {
            this.source_doc = base64ToArrayBuffer(source_pv_pdf)
        }
    }

    // Check l'affichage des éléments selon les infos du PV
    checkEtatInterface() {
        this.checkEnCours = true
        this.showError = false
        this.message_error = ''
        const pv = this.$store.getters['pv/pvSelect']
        this.contentPV = pv?.datas || ''
        if (pv && pv.submitted_at !== null) {
            // Le pv est en attente de signature
            this.modeRedaction = false
            this.modeConsultation = true
            this.modeSignature = false
            this.modeValidation = false
            this.$store.state.pv.source_pv_pdf = ''
            this.$store.dispatch('pv/getPDF', pv.id).then(() => {
                this.checkEnCours = false
            })
        } else if (pv && pv.validated_at !== null) {
            // Le pv est signé, on reste en mode consultation
            this.modeRedaction = false
            this.modeSignature = false
            this.modeConsultation = true
            this.modeValidation = false
            this.$store.state.pv.source_pv_pdf = ''
            this.$store.dispatch('pv/getPDF', pv.id).then(() => {
                this.checkEnCours = false
            })
        } else {
            // Mode redaction pas de validation demandée
            this.modeRedaction = true
            this.modeConsultation = false
            this.modeSignature = false
            this.modeValidation = false
            this.contentPV = ''
            this.checkEnCours = false
        }
    }

    // Bouton Retour
    back() {
        if (!this.checkEnCours) {
            this.checkEtatInterface()
        }
    }

    // Récupère la maj du datas
    stock_datas(datas: any) {
        this.contentPV = datas
    }

    // Sauvegarde des datas
    saveDatas(datas: string) {
        this.showWarning = false
        this.message_error = ''
        if (datas === null || datas === '') {
            this.message_error = 'Vous ne pouvez pas enregistrer un Procès-verbal vierge de contenu !'
            this.showWarning = true
        } else {
            this.message_error = ''
            this.showWarning = false
            this.contentPV = datas
            const richtext = {
                datas: this.contentPV
            }
            this.$store.dispatch('pv/updatePv', { pv_id: this.$store.getters['pv/pvSelect'].id, datas: richtext }).then(() => {
                this.contentPV = richtext.datas
            })
        }
    }

    // Ouvre le mode validation d'un pv
    validePV() {
        // Enregistrement avant de basculer en mode Terminer
        this.showWarning = false
        this.message_error = ''
        if (this.contentPV === null || this.contentPV === '') {
            this.message_error = 'Vous ne pouvez pas enregistrer un Procès-verbal vierge de contenu !'
            this.showWarning = true
        } else {
            const richtext = {
                datas: this.contentPV
            }
            const pv = this.$store.getters['pv/pvSelect']
            this.$store.dispatch('pv/updatePv', { pv_id: pv.id, datas: richtext }).then(() => {
                this.$store.state.pv.source_pv_pdf = ''
                this.$store.dispatch('pv/getPDF', pv.id).then(() => {
                    this.modeRedaction = false
                    this.modeConsultation = true
                    this.modeValidation = true
                })
            })
        }
    }

    // Confirme la validation d'un pv
    confirmerValidation() {
        const datas = {
            submit: 1
        }
        this.$store.dispatch('pv/updatePv', { pv_id: this.$store.getters['pv/pvSelect'].id, datas: datas }).then(() => {
            this.modeValidation = false
            this.modeRedaction = false
            this.modeConsultation = true
        })
    }

    // Ouvre l'aperçu d'un pv
    apercuPV(datas: string) {
        this.showWarning = false
        this.message_error = ''
        if (datas === null || datas === '') {
            this.message_error = 'Vous ne pouvez pas afficher un Procès-verbal vierge de contenu !'
            this.showWarning = true
        } else {
            // Réinit de la source pdf sinon au chargement de pdf, on verra l'éventuel ancien pdf affiché le temps que le nouveau se charge.
            this.$store.state.pv.source_pdf = ''

            this.message_error = ''
            this.showWarning = false
            this.contentPV = datas
            const richtext = {
                datas: this.contentPV
            }

            const pv = this.$store.getters['pv/pvSelect']
            this.$store.dispatch('pv/updatePv', { pv_id: pv.id, datas: richtext }).then(() => {
                this.$store.state.pv.source_pv_pdf = ''
                this.$store.dispatch('pv/getPDF', pv.id).then(() => {
                    this.modeRedaction = false
                    this.modeConsultation = true
                })
            })
        }
    }

    // Ouvre le mode signature d'un PV
    signerPV() {
        this.$store.state.pv.source_pv_pdf = ''
        this.$store.dispatch('pv/getPDF', this.$store.getters['pv/pvSelect'].id).then(() => {
            this.modeSignature = true
            this.modeRedaction = false
            this.modeValidation = false
        })
    }

    // Annule la validation et la signature d'un pv
    annuler() {
        const datas = {
            cancel: 1
        }
        this.$store.state.pv.error = null
        this.$store.dispatch('pv/updatePv', { pv_id: this.$store.getters['pv/pvSelect'].id, datas: datas })
    }

    // Enregistre la signature si besoin et update le pv
    saveSignature(signature: string) {
        this.showError = false
        this.message_error = ''

        const datas = {
            validate: 1,
            signature: signature
        }
        this.$store.dispatch('pv/updatePv', { pv_id: this.$store.getters['pv/pvSelect'].id, datas: datas })
    }

    // Affiche si des erreurs de signature sont retournées
    showErrorSignature(error: string) {
        if (error !== '') {
            this.showError = true
            this.message_error = error
        } else {
            this.showError = false
            this.message_error = ''
        }
    }

    load() {
        if (!this.checkEnCours) {
            this.checkEtatInterface()
        }
    }

    mounted() {
        for (let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'signatureMode') {
                this.signatureMode = this.$store.state.auth.user.parameters[i].value
            }
        }

        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
